<script>
import AppBanner from '@/components/shared/AppBanner';

export default {
	name: 'Home',
	components: {
		AppBanner,
	},
};
</script>

<template>
	<div class="container mx-auto flex flex-grow">
		<AppBanner />
	</div>
</template>

<style scoped></style>
<script>
import bio from '../../data/bio';
import feather from 'feather-icons';

export default {
	data() {
		return { bio }
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<div class="container mx-auto">
		<div>
			<!-- Footer social links -->
			<div class="flex flex-col justify-center items-center">
				
				<p
					class="lg:hidden font-general-semibold text-2xl sm:text-3xl font-semibold text-ternary-dark dark:text-ternary-light mb-5">
					{{ bio.footerTitle }}
				</p>
				
				<ul class="flex gap-4 sm:gap-8">
					<a v-for="social in bio.socialLinks" :key="social.id" :href="social.url" target="__blank"
						class="
						text-gray-400 
						hover:text-indigo-500 
						dark:hover:text-white 
						cursor-pointer 
						rounded-lg 
						bg-gray-50 
						dark:bg-secondary-dark 
						hover:bg-gray-100
						dark:hover:bg-indigo-500 
						shadow-sm 
						p-4 
						duration-500">
						<i :data-feather="social.icon" class="w-5 sm:w-8 h-5 sm:h-8"></i>
					</a>
				</ul>
			</div>

			<!-- Footer copyright -->
			<div class="mt-5 mb-5 flex justify-center items-center text-center">
				<div class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light">
					{{ bio.footerText }}
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>

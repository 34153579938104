<script>
// import ThemeSwitcher from './ThemeSwitcher.vue';

export default {
	// components: { ThemeSwitcher },
	data() {
		return { isOpen: false }
	},
};
</script>

<template>
	<nav id="nav" class="sm:container sm:mx-auto">
		<!-- Header start -->
		<div class="z-10 max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:justify-between sm:items-center my-4">
			<!-- Header menu links and small screen hamburger menu -->
			<div class="flex justify-end items-center px-4 sm:px-0">

				<!-- Theme switcher small screen -->
				<!-- <theme-switcher class="block sm:hidden" /> -->

				<!-- Small screen hamburger menu -->
				<div class="sm:hidden">
					<button @click="isOpen = !isOpen" type="button"
						class="cursor-pointer rounded-lg bg-gray-50 dark:bg-secondary-dark hover:bg-gray-100 dark:hover:bg-indigo-500 shadow-sm px-2.5 py-2 duration-500"
						aria-label="Hamburger Menu">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
							class="h-7 w-7 fill-current text-gray-400 hover:text-indigo-500 dark:hover:text-white ">
							<path v-if="isOpen" fill-rule="evenodd"
								d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
								clip-rule="evenodd"></path>
							<path v-if="!isOpen" fill-rule="evenodd"
								d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z">
							</path>
						</svg>
					</button>
				</div>
			</div>

			<!-- Header links -->
			<div :class="isOpen ? 'block' : 'hidden'"
				class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 p-5 justify-center items-center shadow-lg sm:shadow-none">
				<router-link @click="isOpen = false" :to="{name: 'Home'}"
					class="font-general-medium block text-center text-2xl font-medium text-ternary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2"
					aria-label="Home">Home</router-link>
				<router-link @click="isOpen = false" :to="{name: 'Projects'}"
					class="font-general-medium block text-center text-2xl font-medium text-ternary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2"
					aria-label="Projects">Projects</router-link>
				<router-link @click="isOpen = false" :to="{name: 'About'}"
					class="font-general-medium block text-center text-2xl font-medium text-ternary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2"
					aria-label="About Me">About Me</router-link>
			</div>

			<!-- Header right section buttons -->
			<div class="hidden sm:flex justify-between items-center flex-col md:flex-row">
				<!-- Theme switcher large screen -->
				<!-- <theme-switcher /> -->
			</div>
		</div>
	</nav>
</template>

<style scoped>
.router-link-exact-active {
	@apply text-indigo-700;
	@apply dark:text-indigo-400;
	@apply font-medium;
}
</style>

<script>
import bio from '../../data/bio';
import feather from 'feather-icons';

export default {
	data() {
		return { bio }
	},
	mounted() {
		feather.replace()
	},
};
</script>

<template>
	<div class="grid grid-cols-1 md:grid-cols-2 justify-items-center items-center content-center	">
		<!-- Banner left contents -->
		<div>
			<h1
				class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-transparent uppercase bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 bg-clip-text">
				{{ bio.title }}
			</h1>

			<p
				class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-center leading-none text-gray-400">
				{{ bio.subtitle }}
			</p>

			<div class="flex justify-center sm:block">
				<a download="Evgenii_Kolgin_iOS_Developer.pdf" :href="bio.cvpath"
					class="
					flex 
					justify-center 
					items-center 
					mt-6 
					text-lg
					shadow-sm
					p-3 
					rounded-lg 
					bg-indigo-50 
					dark:bg-secondary-dark  
					focus:ring-1 
					focus:ring-indigo-900 
					text-gray-400  
					dark:hover:text-white 

					hover:bg-indigo-500 
					hover:text-white duration-500"
					aria-label="Download Resume">
					<i data-feather="arrow-down-circle" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
					<span class="text-sm sm:text-lg font-general-medium duration-100">{{ bio.buttonTitle }}
					</span>
				</a>
			</div>
		</div>

		<!-- Banner right illustration -->
		<div>
			<lottie-animation :loop="true" ref="anim" :animationData="require('@/assets/animations/codework.json')" />
		</div>
	</div>
</template>

<style scoped></style>


<!-- class="
	text-gray-400 
	hover:text-indigo-500 
	dark:hover:text-indigo-400 
	cursor-pointer 
	rounded-lg 
	bg-gray-50 
	dark:bg-secondary-dark 
	hover:bg-gray-100 
	shadow-sm 
	p-4 
	duration-500"> -->

<!-- 
	mt-6 
					text-lg 
					border 
					border-indigo-200 
					dark:border-ternary-dark 
					p-3 
					rounded-lg 
					bg-indigo-50 
					focus:ring-1 
					focus:ring-indigo-900 
					hover:bg-indigo-500 
					text-gray-500 
					hover:text-white duration-500" -->
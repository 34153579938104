// You can edit all in one place

const bio =
{
    title: 'Hi, Iam Evgenii',
    subtitle: 'iOS Developer',
    cvpath: '/files/Evgenii_Kolgin_iOS_Developer.pdf',
    buttonTitle: 'Download Resume',
    profilepath: require('@/assets/images/profile.webp'),
    about: [
        { id: 1, paragraph: "Hello there! I'm Evgenii, a passionate iOS developer with a knack for crafting elegant and efficient mobile applications." },
        { id: 2, paragraph: "My journey into the world of app development began with a deep curiosity and a desire to create meaningful experiences for users. From designing sleek user interfaces to diving into the intricacies of Swift programming, every step of the way has been a thrilling adventure." },
        { id: 3, paragraph: "In addition to my technical skills, I bring a strong commitment to continuous learning and improvement. I'm always exploring new technologies, staying updated with the latest trends, and refining my craft to deliver top-notch solutions." },
        { id: 4, paragraph: "I'm excited about the opportunity to contribute to innovative projects, tackle new challenges, and make a positive impact in the iOS development community. Let's build something remarkable together!" },
        // { id: 1, paragraph: "Hello, I'm a beginner iOS Developer passionate about mobile apps. Although I'm still gaining experience in this field, you can see some of my starter projects below." },
        // { id: 2, paragraph: "I used to work in sales and have a lot of experience in communication with people and sales management." },
        // { id: 3, paragraph: "But Im also a big fan of technologies. I love all these things that change our lives for the better living..." },
    ],
    footerTitle: 'Contact me',
    socialLinks: [
        { id: 1, name: 'GitHub', icon: 'github', url: 'https://github.com/Colgates' },
        { id: 2, name: 'Telegram', icon: 'send', url: 'https://t.me/Colgates' },
        { id: 3, name: 'Gmail', icon: 'mail', url: 'mailto: kolgin.ev@gmail.com' },
        { id: 4, name: 'LinkedIn', icon: 'linkedin', url: 'https://www.linkedin.com/in/evgenii-kolgin' },
    ],
    footerText: 'Created with ❤️ Vue.js & TailwindCSS'
}

export default bio;
